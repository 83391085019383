ul {
    list-style: none;
}

button {
    cursor: pointer;
}

button:hover {
    transform: scale(1.02);
}