.wrapper {
  display: grid;
  grid-template-columns: min-content 1fr;
}

.main {
  background: #f7fafc;
  display: grid;
  grid-template-rows: 130px auto;
  row-gap: 70px;
}

.header {
  display: grid;
  padding: 20px;
  background: #ffffff;
  grid-template-columns: auto auto;
  align-items: center;
}

.profile__intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
}

.username {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #4a5568;
}

.profile__avatar {
  width: 60px;
  height: auto;
  margin-bottom: 10px;
}

.main__content {
  display: grid;
  background: #ffffff;
  grid-template-columns: 1fr;
  grid-template-rows: 70px auto;
  padding: 10px 20px;
  row-gap: 15px;
  align-items: start;
}

.main__content h2 {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;

  /* 700 */

  color: #4a5568;
}

.main__content__heading {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  width: 100%;
  height: 50px;
}

.left__content {
  display: flex;
  gap: 20px;
  justify-self: end;
}

.search__input {
  padding: 10px 35px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  color: #4a5568;
}

.search__input:focus {
  outline: none;
}

.search__input::placeholder {
  color: #cbd5e0;
}

.search__icon {
  position: relative;
  left: 30px;
  top: 4px;
}

.new__bot {
  padding: 10px 15px;
  background: #27ae60;
  border: none;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #f7fafc;
}
