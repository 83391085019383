.sidenav {
  display: flex;
  width: 80px;
  height: 100vh;
  background: #1d1d1d;
  justify-content: flex-start;
  color: #fff;
  padding-top: 2rem;
  padding-left: 1rem;
  transition: width 0.5s ease-in-out;
}
.sidenav span {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
}
.logo__large {
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.logo__small {
  position: absolute;
  width: 28px;
}

.sidenav:hover {
  width: 200px;
}
.sidenav:hover span {
  opacity: 1;
  pointer-events: visible;
}
.sidenav:hover .logo__large {
  opacity: 1;
  pointer-events: visible;
}

.sidenav:hover .logo__small {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.nav__list {
  display: grid;
  max-height: 450px;
  margin-top: 5rem;
  justify-content: center;
  align-items: center;
}
li a {
  display: flex;
  height: 30px;
  align-items: center;
  cursor: pointer;
}
li a > img {
  margin-right: 20px;
  width: 25px;
  height: auto;
}

li a.active {
  font-weight: bold;
}
